import { graphql } from "gatsby";
import React from "react";
import LayoutSub from "../components/layout-sub";
import SEO from "../components/seo";
import Sidebar from "../components/sidebar";
import cert1090img from "../images/cert/AAA-10901.png";
import cert1090pdf from "../pdf/EG-intyg-EN-1090-1.pdf";
import cert3834img from "../images/cert/AAA-38342.png";
import cert3834pdf from "../pdf/Certifikat-2018-ISO-3834-2.pdf";

export default ({data}) => (
  <LayoutSub title="Om oss">
    <SEO
      title="Om oss"
      description={data.markdownRemark.frontmatter.seo.description}
      keywords={data.markdownRemark.frontmatter.seo.keywords.toString().split(',')}
    />
    
    <article id="main-article" className="container flex flex-wrap items-start lg:flex-no-wrap mx-auto p-4 md:p-8 xl:p-16">
      <div role="main" className="w-full lg:w-2/3 lg:pr-16 xl:pr-32">
        <div className="article" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />

        <div className="my-8">
          <h3 className="mb-4">Certifikat</h3>
          <div className="flex">
            <div className="mr-8">
              <h5 className="font-bold mb-2">EN 1090-1:2009</h5>
              <a href={cert1090pdf}><img className="max-w-xxs" src={cert1090img} alt="AAA-10901"/></a>
              <p className="text-xxs text-grey-dark italic">Ladda ner PDF</p>
            </div>
            <div className="">
              <h5 className="font-bold mb-2">ISO 3834-2:2005</h5>
              <a href={cert3834pdf}><img className="max-w-xxs" src={cert3834img} alt="AAA-38342"/></a>
              <p className="text-xxs text-grey-dark italic">Ladda ner PDF</p>
            </div>
          </div>
        </div>
      </div>

      <Sidebar data={data.tjanster} />
    </article>
  </LayoutSub>
)

export const query = graphql`
{
  fileName: file(relativePath: { eq: "sub-page-half.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 390) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  markdownRemark(frontmatter: {name: {eq: "Om oss"}}) {
    frontmatter {
      title
      name
      slug
      seo {
        description
        keywords
        title
      }
      text_header {
        heading
      }
    }
    html
  }
  tjanster: allMarkdownRemark(filter: {frontmatter: {type: {eq: "service"}}}, sort: {fields: frontmatter___order}) {
    edges {
      node {
        id
        frontmatter {
          name
          slug
          service_excerpt
        }
      }
    }
  }
  cert1090: file(relativePath: { eq: "cert/1090-1.png" }) {
    childImageSharp {
      fluid(maxWidth: 112) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  cert34011: file(relativePath: { eq: "cert/34011.png" }) {
    childImageSharp {
      fluid(maxWidth: 112) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  cert3834: file(relativePath: { eq: "cert/3834-2.png" }) {
    childImageSharp {
      fluid(maxWidth: 62) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  startsida: markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
    frontmatter {
      seo {
        description
        keywords
        title
      }
      cta_tjanster {
        blocks {
          heading
          body
          url
        }
      }
    }
  }
}
`